<script>
	import { page } from '$app/stores';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import bg1 from './images/page-title-headers-01.jpg';
	import bg2 from './images/page-title-headers-02.jpg';
	import bg3 from './images/page-title-headers-03.jpg';
	import bg4 from './images/page-title-headers-04.jpg';

	let backgroundImages = new Map([
		['solutions', bg1],
		['commercial', bg2],
		['resources', bg3],
		['about-indow', bg4],
	]);
</script>

{#if $page?.data?.showTitle}
	<Section
		backgroundColor="blue"
		innerClass={[
			'pt-[clamp(3rem,5vw,6rem)] md:pt-[clamp(2.5rem,5vw,6rem)] pb-[clamp(2.5rem,5vw,6rem)]',
		].join(' ')}
		backgroundImage={$page.data?.backgroundImage ??
			backgroundImages.get($page?.url?.pathname?.split('/')?.[1]) ??
			null}
		loading="eager"
	>
		<h1 class=" font-bold">
			{$page?.data?.h1 ??
				$page?.data?.title ??
				$page?.url?.pathname?.split('/')?.filter(Boolean)?.slice(-1)}
		</h1>
	</Section>
{/if}
